export default [
  
  { icon: "home", title: "Home", name: "Home", i18n: "Home" },
  { icon: "group", title: "Manage Trainers", name: "Manage Trainers", i18n: "Home" },
  // { icon: "equalizer", title: "Analytics", name: "Analytics", i18n: "Home" },
  { icon: "dashboard", title: "Data Inventory Builder", name: "Skilldb", i18n: "Data Inventory" },
  { header: "Header", name: "Profile Section", i18n: "App" },
  { 
    title: "Profile", 
    group: "pages/profile", 
    icon: "contacts", 
    i18n: "Profile",
items: [     
  {  name: "WorkExp",  
  title: "Work Experience",  
  prependIcon: "dashboard",  
  i18n: "Work Experience"},
  { name: "Certifications", title: "Certifications", prependIcon: "dashboard", i18n: "Certifications" },
  { name: "Testimonials", title: "Testimonials", prependIcon: "dashboard", i18n: "Testimonials" },
  { name: "Refrences", title: "Refrences", prependIcon: "dashboard", i18n: "Refrences" },
  { name: "SkillServices", title: "Skill and services", prependIcon: "dashboard", i18n: "Skill and services" },
  { name: "Topcourse", title: "Top Course", prependIcon: "dashboard", i18n: "Top Course" }
], },
];
